exports.onRouteUpdate = ({ location, prevLocation }) => {
  function tryExecuteMainScript() {
    setTimeout(function() {
      if(window.MainScript) {
        window.MainScript()
      } else {
        tryExecuteMainScript()
      }
    }, 50)
  }

  tryExecuteMainScript()
};
