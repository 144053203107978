// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cennik-js": () => import("./../../../src/pages/cennik.js" /* webpackChunkName: "component---src-pages-cennik-js" */),
  "component---src-pages-galeria-js": () => import("./../../../src/pages/galeria.js" /* webpackChunkName: "component---src-pages-galeria-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jak-przygotowac-sie-do-zabiegu-js": () => import("./../../../src/pages/jak-przygotowac-sie-do-zabiegu.js" /* webpackChunkName: "component---src-pages-jak-przygotowac-sie-do-zabiegu-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-o-mnie-js": () => import("./../../../src/pages/o-mnie.js" /* webpackChunkName: "component---src-pages-o-mnie-js" */),
  "component---src-pages-opinie-js": () => import("./../../../src/pages/opinie.js" /* webpackChunkName: "component---src-pages-opinie-js" */),
  "component---src-pages-temat-klinika-chirurgii-plastycznej-js": () => import("./../../../src/pages/temat/klinika-chirurgii-plastycznej.js" /* webpackChunkName: "component---src-pages-temat-klinika-chirurgii-plastycznej-js" */),
  "component---src-templates-procedure-js": () => import("./../../../src/templates/procedure.js" /* webpackChunkName: "component---src-templates-procedure-js" */)
}

